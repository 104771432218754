import { Grid, Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { Button } from '../button/button'
import styled from 'styled-components'
import { irem } from '../../utils'

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(1199)};
  margin: auto;
  position: relative;
`

export const StyledImageBackground = styled.div`
  width: 100%;
  filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => alpha(props.theme.palette.text.secondary, 0.19)});
  position: relative;
  padding: 0;
  z-index: 1;
  background: transparent;
  -webkit-transform: translate3d(0, 0, 0);
`

export const StyledTrapezoid = styled.div`
  width: calc(100% - ${props => props.theme.typography.pxToRem(18)});
  clip-path: polygon(0 0, calc(100% - 40px) 0%, 100% 100%, 0% 100%);
  z-index: 10;
  background: ${props => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${irem(32)} ${irem(38)};

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    width: calc(100% - ${props => props.theme.typography.pxToRem(39)});
    clip-path: polygon(0 0, 92% 0%, 100% 100%, 0% 100%);
    gap: 0 ${props => props.theme.typography.pxToRem(120)};
    padding: ${irem(80)} 0 ${irem(85)};
  }
`

export const StyledDoubleTriangle = styled.div`
  position: absolute;
  bottom: ${props => props.theme.typography.pxToRem(-6)};
  right: ${props => props.theme.typography.pxToRem(-6)};
`

export const StyledHorizontalHeadingSection = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${props => props.theme.breakpoints.up('md')} {
    width: auto;
  }

  &::before {
    content: '';
    height: ${props => props.theme.typography.pxToRem(4)};
    width: ${props => props.theme.typography.pxToRem(30)};
    background-color: ${props => props.theme.palette.primary.main};
    margin-bottom: ${props => props.theme.typography.pxToRem(18)};

    ${props => props.theme.breakpoints.up('md')} {
      height: ${props => props.theme.typography.pxToRem(5)};
      width: ${props => props.theme.typography.pxToRem(40)};
      margin-bottom: ${props => props.theme.typography.pxToRem(30)};
    }
  }
`

export const StyledVerticalHeadingSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(22)};
  font-weight: 500;
  margin-bottom: ${props => props.theme.typography.pxToRem(20)};
  line-height: 1.5;
  color: ${props => props.theme.palette.text.primary};

  &::first-line {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }

  &::before {
    content: '';
    float: right;
    width: 20%;
    height: ${props => props.theme.typography.pxToRem(1)};
  }

  ${props => props.theme.breakpoints.up(400)} {
    font-size: ${props => props.theme.typography.pxToRem(26)};

    &::before {
      width: ${props => props.theme.typography.pxToRem(50)};
    }

    &::first-line {
      font-size: ${props => props.theme.typography.pxToRem(22)};
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(40)};
    margin-bottom: 0;
    line-height: ${props => props.theme.typography.pxToRem(44)};

    &::first-line {
      font-size: ${props => props.theme.typography.pxToRem(30)};
      width: ${props => props.theme.typography.pxToRem(10)};
    }
  }
`

export const StyledButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
    height: ${props => props.theme.typography.pxToRem(51)};
    width: ${props => props.theme.typography.pxToRem(124)};
    margin-top: 0;
  }
`

export const StyledButtonContainer = styled(Grid)`
  margin: 0;

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(70)};
  }

  > a {
    text-decoration: none;
  }
`